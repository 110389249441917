export function getCurrentSpanishGreeting(): string {
    const now = new Date();
    const hour = now.getHours();

    let greeting = 'Good morning';

    if (hour > 14) {
        greeting = 'Good afternoon';
    } else if (hour > 21) {
        greeting = 'Good evening';
    }

    return greeting;
}

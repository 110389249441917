import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './voice-recognition-demo.css';
import { UserRecognizer } from '../user-recognizer/user-recognizer';
import { UserRegistration } from '../user-registration/user-registration';
import { UserVerifier } from '../user-verifier/user-verifier';
import { Translation } from 'react-i18next';
import { configureI18Next } from '../../services/i18n';
import { MIDSDK, MIDSDKBiometricMethod } from '../../../../assets/js/mobbid-sdk-core-1.30.1.js';
import { MIDSDKVoice } from '../../../../assets/js/mobbid-sdk-voice-1.30.1.js';
import { IdentificationRegistry } from '../../services/identification-registry';

interface VoiceRecognitionDemoProps {
    baseURL: string;
    applicationId: string;
    registrationURL: string;
    logoURL: string;
    language: string;
}

const getRandomKey = () =>
    Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5);


export class VoiceRecognitionDemo extends React.Component<VoiceRecognitionDemoProps, any> {
    
    private midSDK = undefined
    private registry = new IdentificationRegistry(this.props.registrationURL);

    constructor(props: any) {
        super(props);
        this.state = {
            registering: false,
            identifying: false,
            verifying: false,
            recognitionKey: getRandomKey(),
            initialized: false,
            livenessMode: 0,
            userNotVerified: false,
            score: undefined
        };
        this.registry.getAuthToken().then(response=>{
            this.midSDK = new MIDSDK(this.props.baseURL, response.authToken, this.props.applicationId);   
            this.midSDK.addBiometricMethod(MIDSDKBiometricMethod.VOICE, new MIDSDKVoice());
        });
    }

    register(): void {
        this.setState({ ...this.state, registering: true });
    }

    onUserIdentified = (score: any): void => {
        console.log('identified user');
        this.setState({ ...this.state, score: score, detectedUser: true, unknownUser: false, userNotVerified: false });
    };

    onUnknownUser = (): void => {
        console.log('unknown user');
        this.setState({ ...this.state, detectedUser: false, unknownUser: true});
    };

    onUserVerified = (score: any): void => {
        console.log('user verified');
        this.setState({ ...this.state, detectedUser: true, unknownUser: false, userNotVerified: false, score: score });
    };

    onUserNotVerified = (score: any): void => {
        console.log('user not verified');
        this.setState({ ...this.state, detectedUser: true, unknownUser: false, userNotVerified: true, score: score });
    };

    onRegister = (): void => {
        this.setState({ ...this.state, registering: true, initialized: true });
    };

    onRestart = (): void => {
        location.reload();
    };

    onRetry = (): void => {
        this.setState({
            ...this.state,
            user: '',
            detectedUser: false,
            unknownUser: false,
            registering: false,
            userNotVerified: false,
            recognitionKey: getRandomKey(),
            score: undefined
        });
    };

    onVerify = (): void => {
        this.setState({
            ...this.state,
            initialized: true,
            verifying: true
        });
    };

    onIdentify = (): void => {
        this.setState({
            ...this.state,
            initialized: true,
            identifying: true
        });
    };

    getCurrentStep(): JSX.Element {
        const recognitionStep = (
            <UserRecognizer
                midSDK={this.midSDK}
                registrationURL={this.props.registrationURL}
                onSuccess={this.onUserIdentified}
                onUnknownUser={this.onUnknownUser}
                key={this.state.recognitionKey}    
                language={this.props.language}
            ></UserRecognizer>
        );

        const verificationStep = (
            <UserVerifier
                midSDK={this.midSDK}
                registrationURL={this.props.registrationURL}                
                onSuccess={this.onUserVerified}
                onUnknownUser={this.onUnknownUser}
                onUserNotVerified={this.onUserNotVerified}
                key={this.state.recognitionKey}
                language={this.props.language}              
            ></UserVerifier>
        );

        const registrationStep = (
            <UserRegistration
                midSDK={this.midSDK}
                registrationURL={this.props.registrationURL}
                applicationId={this.props.applicationId}
                language={this.props.language}
            ></UserRegistration>
        );

        return this.state.registering ? 
            registrationStep : 
            this.state.identifying ? recognitionStep : verificationStep
    }

    getScore(): JSX.Element {
        if (this.state.score == undefined) {
            return undefined
        } else if (this.state.score.liveness == undefined) {
            return (
                <div>
                    <h3>Score: {this.state.score.identityVerification}</h3>
                </div>
            );
        } else {
            return (
                <div>
                    <h3>Score: {this.state.score.identityVerification}</h3>
                    <ul>
                        <li>Matching: {this.state.score.voiceMatching}</li>
                        <li>Liveness: {this.state.score.liveness}</li>
                    </ul>
                </div>
            );
        }
    }

    render(): any {
        return (
            <Translation>
                {t => (
                    <div>
                        {this.state.initialized === false ? (
                            <div id="welcome">
                                <img className="logo" src={this.props.logoURL} alt="Logo"></img>
                                <h1>{t('User recognition')}</h1>
                                <p className="summary">{t('welcome.message')}</p>
                                <button onClick={this.onRegister}>{t('Register')}</button>
                                <button onClick={this.onVerify}>{t('Verify')}</button>                           
                            </div>
                        ) : (
                            <div className="voice-recognition-demo">
                                <h1>{this.state.registering ? t('Registration') : this.state.identifying ? t('Searching user') : t('Verifying user') }</h1>
                                {this.getCurrentStep()}
                                {this.getScore()}
                                <div className="buttons">
                                <button
                                        className={
                                            this.state.unknownUser || this.state.userNotVerified
                                                ? 'button'
                                                : 'button hidden'
                                        }
                                        onClick={this.onRetry}
                                    >
                                        {this.state.registering ? t('Go back') : t('Retry')}
                                    </button>
                                    <button
                                        className='button'
                                        onClick={this.onRestart}
                                    >
                                        {t('Home')}
                                    </button>
                                    <button
                                        className={
                                            this.state.unknownUser && !this.state.registering
                                                ? 'button'
                                                : 'button hidden'
                                        }
                                        onClick={this.onRegister}
                                    >
                                        {t('Add new user')}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export function renderDemo(
    parent: HTMLElement,
    baseURL: string,
    registrationURL: string,
    applicationId: string,
    logoURL: string,
    language: string,
): void {
    let availableLanguage = language == "es" ? language : "en"
    configureI18Next(availableLanguage);
    ReactDOM.render(
        <VoiceRecognitionDemo
            baseURL={baseURL}
            applicationId={applicationId}
            registrationURL={registrationURL}
            logoURL={logoURL}
            language={availableLanguage}
        />,
        parent,
    );
}
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as translations from '../../../i18n/demo-translations.json';

export function configureI18Next(language: string): void {
    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources: translations,
            lng: language,
            keySeparator: false, // we do not use keys in form messages.welcome

            interpolation: {
                escapeValue: false, // react already safes from xss
            },
        });
}

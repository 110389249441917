import { Base64 } from './base64';

export class IdentificationRegistry {
    constructor(private baseURL: string) {}

    getAuthToken(): Promise<any> {
        return fetch(`${this.baseURL}/auth`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json());
    }

    register(userID: string, name: string, email: string, avatar: Base64): Promise<any> {
        avatar = avatar.replace('data:image/png;base64,', '');

        return fetch(`${this.baseURL}/user`, {
            method: 'POST',
            body: JSON.stringify({ biometricId: userID, name, email, avatar }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => response.json());
    }

    remove(userID: string): Promise<any> {
        return fetch(`${this.baseURL}/user/${userID}`, {
            method: 'DELETE',
        }).then(response => response.json());
    }

    get(userID: string): Promise<any> {
        return fetch(`${this.baseURL}/user/${userID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => response.json());
    }

    confirmIdentification(userID: string): Promise<any> {
        return this.sendEvent(userID, 'VOICE_IDENTIFICATION_CONFIRMED');
    }

    notifyIdentificationError(userID: string): Promise<any> {
        return this.sendEvent(userID, 'VOICE_IDENTIFICATION_DISCARDED');
    }

    private sendEvent(userID: string, event: string): Promise<any> {
        return fetch(`${this.baseURL}/event`, {
            method: 'POST',
            body: JSON.stringify({ code: event, extra: { biometricId: userID } }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }
}

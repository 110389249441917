import * as React from 'react';
import './user-recognizer.css';
import { CSSTransition } from 'react-transition-group';
import { IdentificationRegistry } from '../../services/identification-registry';
import { Translation } from 'react-i18next';
import { MIDSDKBiometricMethod, IdentificationObserver } from '../../../../assets/js/mobbid-sdk-core-1.30.1.js';
import { VoiceUIObserver } from '../../../../assets/js/mobbid-sdk-voice-1.30.1.js';
import * as DateUtils from '../../../ui/common/date-utils';

export class UserRecognizer extends React.Component<any, any> {
    
    private registry: IdentificationRegistry;
    private voiceUIObserver = new VoiceUIObserver();

    constructor(props: any) {
        super(props);
        this.state = {
            initialized: false,
            identified: false,
            unidentified: false,
            timeout: false,
            error: undefined,
            eventSent: false,
            detecting: false,
            user: '',
            userId: '',
        };
        this.registry = new IdentificationRegistry(this.props.registrationURL);        
    }

    componentDidMount(): void {
        const initContainer = this.props.midSDK.setContainer(MIDSDKBiometricMethod.VOICE, document.getElementById('voiceContainer'), this.props.language, this.voiceUIObserver);
        initContainer.then(result => {
            this.setState({ ...this.state, initialized: true });
            const identificationObserver = new IdentificationObserver();
            this.props.midSDK.identify(MIDSDKBiometricMethod.VOICE, identificationObserver);
            identificationObserver
                .errors()
                .subscribe(error => {
                    this.onError(error);                    
                });
            identificationObserver
                .identified()
                .subscribe(result => {                    
                    this.onuserIdentified(result);
                });
            identificationObserver
                .notIdentified()
                .subscribe(() => {
                    this.onUserNotIdentified();
                });
            this.voiceUIObserver
                .progress()
                .subscribe(result => {     
                    this.setState({ ...this.state, error: undefined }); 
                    if (result >= 1) {           
                        this.setState({ ...this.state, detecting: true });
                    }
            });
            this.voiceUIObserver
                .errors()
                .subscribe(error => {   
                    this.onError(error);
            });
        });
    }

    onError(error: string): void {
        console.log("ERROR: " + error);
        if (error === 'TIMEOUT') {
            this.setState({ ...this.state, timeout: true, detecting: false });
            this.props.onUserNotVerified();
        } else {
            this.setState({ ...this.state, error: error, detecting: false });
        }
    }

    onuserIdentified(result: any): void {
        console.log("IDENTIFIED");
        console.log(result);
        this.setState({ ...this.state, identified: true, detecting: false });
        const id = result.ranking[0].userId;
        let user = id;
        this.registry
            .get(id)
            .then((result: any) => {
                if ('name' in result) {
                    user = result.name;
                }
            })
            .catch(() => {
                // noop
            })
            .finally(() => {
                this.setState({ ...this.state, user: user, userId: id, identified: true });
                this.props.onSuccess(result.ranking[0].score);
            });
    }

    onUserNotIdentified(): void {
        console.log("NOT IDENTIFIED");
        this.setState({ ...this.state, unidentified: true, detecting: false });
    }

    confirmEventSent(): void {
        this.setState({ ...this.state, eventSent: true });
    }

    onConfirm = () => {
        this.registry.confirmIdentification(this.state.userId).finally(() => this.confirmEventSent());
    };

    onNotifyError = () => {
        this.registry.notifyIdentificationError(this.state.userId).finally(() => this.confirmEventSent());
    };

    render(): any {
        return (
            <div id="stepContainer">
                <div id="voiceContainer"></div>
                <div className="status">
                    <Translation>                
                        {t => (
                            <div>
                                <CSSTransition in={this.state.identified} timeout={1000} classNames="message">
                                    <span className="message">{t(DateUtils.getCurrentSpanishGreeting()) + ' ' + this.state.user + '!'}</span>
                                </CSSTransition>
                                <CSSTransition in={this.state.error} timeout={1000} classNames="message">
                                    <span className="message">{t(this.state.error)}</span>
                                </CSSTransition>
                                <CSSTransition in={this.state.timeout} timeout={1000} classNames="message">
                                    <span className="message">{t('Timeout while detecting voice.')}</span>
                                </CSSTransition>                                
                                <CSSTransition in={this.state.unidentified} timeout={1000} classNames="message">
                                    <span className="message">{t('User can\'t be identified...')}</span>
                                </CSSTransition>
                                <CSSTransition in={this.state.initialized && this.state.detecting} timeout={0} classNames="message">
                                    <span className="message">{t('Identifying user.') + " " + t('Please wait...')}</span>
                                </CSSTransition>
                                <CSSTransition in={this.state.initialized && this.state.detecting} timeout={0} classNames="spinner">
                                    <img className="spinner" src="assets/img/spinner.png" alt="spinner"/>
                                </CSSTransition>
                                <div className={ this.state.identified && !this.state.eventSent ? 'buttons' : 'buttons hidden' } >
                                    <button className="button success" onClick={this.onConfirm}>
                                        {t('It is me!')}
                                    </button>
                                    <button className="button error" onClick={this.onNotifyError}>
                                        {t('It is not me!')}
                                    </button>
                                </div>
                            </div>
                        )}
                    </Translation>
                </div>
            </div>
        );
    }
}
